<template>
  <sm-editable-item
    v-model="form"
    :controllerName="controllerName"
    :isLoading="isLoadingPage"
    :breadcrumbs="breadcrumbs"
    :pageHeader="pageHeader"
    :fields="fields"
    :disabledSaveButton="isEqual"
    @save="onSave('SupportGisTypes')"
    @cancel="onCancel('SupportGisTypes')"
  />
</template>

<script>
// mixins
import editableListItemEdit from '@/mixins/editableListItemEdit.js';
// vuex
import { mapState, mapActions } from 'vuex';
// components
import SmEditableItem from '@/components/views/SmEditableItem.vue';

export default {
  name: 'SupportGisTypeEdit',

  components: {
    SmEditableItem,
  },

  mixins: [editableListItemEdit],

  data() {
    return {
      isLoadingPage: false,
      controllerName: 'SupportGisTypes',
      pageHeader: 'Редактирование типа заявок',
      breadcrumbs: [
        {
          text: 'Администрирование',
          route: { name: 'AdministrationMain' },
        },
        {
          text: 'Типы заявок',
          route: { name: 'SupportGisTypes' },
        },
        {
          text: 'Редактирование типа заявок',
        },
      ],
      form: {},
    };
  },

  computed: {
    ...mapState({
      item: (state) => state.editableList.item,
      initiationStatuses: (state) => state.common.initiationStatuses,
      periodicity: (state) => state.common.periodicity,
      periodSupportGisTypes: (state) => state.common.periodSupportGisTypes,
    }),

    fields() {
      return [
        {
          form: [
            {
              type: 'text',
              key: 'name',
              label: 'Наименование',
            },
            {
              type: 'select',
              key: 'statusId',
              label: 'Статус',
              list: this.initiationStatuses.data,
            },
            {
              type: 'select',
              key: 'periodicityByMonths',
              label: 'Периодичность по месяцам',
              list: this.periodSupportGisTypes.data,
            },
            {
              type: 'select',
              key: 'periodicity',
              label: 'Периодичность',
              list: this.periodicity.data,
            },
            {
              type: 'number',
              key: 'day',
              label: 'День',
            },
            {
              type: 'number',
              key: 'month',
              label: 'Месяц',
            },
            {
              type: 'checkbox',
              key: 'isUo',
              label: 'Доступна для УО',
            },
            {
              type: 'checkbox',
              key: 'isTsg',
              label: 'Доступна для ТСЖ',
            },
            {
              type: 'checkbox',
              key: 'isRso',
              label: 'Доступна для РСО',
            },
            {
              type: 'checkbox',
              key: 'isTko',
              label: 'Доступна для ТКО',
            },
            {
              type: 'number',
              key: 'reportingPeriod',
              label: 'Отчетный период',
            },
          ],
        },
      ];
    },
  },

  created() {
    this.isLoadingPage = true;
    const initiationStatuses = this.getCommonList({
      name: 'InitiationStatuses',
    });
    const periodicity = this.getCommonList({
      name: 'Periodicity',
    });
    const periodSupportGisTypes = this.getCommonList({
      name: 'PeriodSupportGisTypes',
    });

    Promise.all([initiationStatuses, periodicity, periodSupportGisTypes]).then(
      () => {
        this.getItem({ name: this.controllerName, id: this.id })
          .then(() => {
            this.form = this.lodash.cloneDeep(this.item.data);
          })
          .finally(() => {
            this.isLoadingPage = false;
          });
      }
    );
  },

  methods: {
    ...mapActions({
      getItem: 'editableList/getItem',
      updateItem: 'editableList/updateItem',
      getCommonList: 'common/getCommonList',
    }),
  },
};
</script>
